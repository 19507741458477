<template>
  <v-container>
    <confirmation-dialog
      :showDialog.sync="showConfirmationDialog"
      message="Are you sure you want to delete this file?"
      @confirm="deleteFile"
      @cancel="cancel"
    ></confirmation-dialog>
    <h1 class="text-title">Download Portal</h1>
    <v-text-field
      v-if="validate"
      clearable
      v-model="searchValue"
      append-icon="mdi-magnify"
      label="Search title.."
      single-line
      hide-details
      :color="colorTheme.textField"
    >
    </v-text-field>
    <template v-if="folders">
      <v-list dense nav>
        <v-list-item class="highlight" @click="s3Files('/')">
          <v-list-item-icon>
            <v-icon v-text="'mdi-folder'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>/</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="folder in folders"
          :key="folder"
          class="highlight"
          @click="s3Files(folder)"
        >
          <v-list-item-icon>
            <v-icon v-text="'mdi-folder'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ folder }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template v-if="filteredFiles">
      <v-list dense nav>
        <v-list-item
          class="highlight"
          v-for="file in filteredFiles"
          :key="file"
        >
          <v-list-item-icon @click="download(file)">
            <v-icon v-text="'mdi-file'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title @click="download(file)">
            {{ formattedFile(file, activeFolder) }}
          </v-list-item-title>
          <template>
            <v-list-item-icon>
              <v-icon small @click="showConfirmation(file)" class="delete-icon">
                mdi-delete
              </v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-list>
    </template>
  </v-container>
</template>

<script>
import s3ls from "s3-ls";
import aws from "aws-sdk";
import { getS3BucketPrefix } from "@/config/organization-config.js";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { mapState } from "vuex";
import amplitude from "amplitude-js"

export default {
  components: { ConfirmationDialog },
  data: () => ({
    searchValue: "",
    folders: null,
    activeFolder: null,
    files: null,
    fileToDelete: null,
    showConfirmationDialog: false,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("userConfig", ["colorTheme"]),
    validate() {
      if (!this.activeFolder) {
        return false;
      }
      if (this.activeFolder !== "/") {
        return true;
      }
      return false;
    },
    filteredFiles() {
      if (this.files === null) {
        return;
      }
      return this.files.filter(file => {
        return file.toLowerCase().match(this.searchValue.toLowerCase());
      });
    },
    bucket() {
      if (this.user && this.user.organization) {
        let bucketPrefix = getS3BucketPrefix(this.user.organization);
        return `${bucketPrefix}-billing-exports`;
      } else {
        return "dev-billing-exports";
      }
    },
  },
  watch: {
    searchValue(val) {
      if (val === null || val === undefined) {
        this.searchValue = "";
      }
    }
  },
  methods: {
    async s3Files(folder = "/") {
      try {
        this.searchValue = "";
        const lister = s3ls({ bucket: this.bucket });
        let { files, folders } = await lister.ls(folder);
        this.folders = folders.reverse();
        this.activeFolder = folder;
        this.files = files.reverse();
      } catch (e) {
        console.log("error ", e);
      }
    },
    download(file) {
      const s3 = new aws.S3();
      const params = { Bucket: this.bucket, Key: file };
      const URL = s3.getSignedUrl("getObject", params);
      const link = document.createElement("a");
      link.href = URL;
      link.target = "_blank";
      link.click();
      amplitude.getInstance().logEvent("Download Portal [Download File]", {
          "File": file
      });
    },
    // Remove folder name from file
    formattedFile(file, folder) {
      return file.replace(folder, "");
    },
    showConfirmation(file) {
      this.showConfirmationDialog = true;
      this.fileToDelete = file;
    },
    cancel() {
      this.fileToDelete = null;
    },
    async deleteFile() {
      console.log("Deleting " + this.fileToDelete);
      const s3 = new aws.S3();
      const params = { Bucket: this.bucket, Key: this.fileToDelete };
      await s3.headObject(params).promise();
      console.log("File found");
      try {
        await s3.deleteObject(params).promise();
        console.log("Deleted successfully");
        this.s3Files(this.activeFolder);
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    aws.config.setPromisesDependency();
    aws.config.update({
      accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_REGION,
    });
    await this.s3Files();
  },
};
</script>
<style scoped>
.highlight:hover {
  background-color: rgb(13, 175, 175);
  cursor: pointer;
}
.delete-icon:hover {
  color: var(--v-error-base);
}
</style>
